var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swing-card-shell",class:{
		'swing-card-shell--back': _vm.localVisibleSide === 'Back',
		'swing-card-shell--front': _vm.localVisibleSide === 'Front'
	}},[_c('div',{staticClass:"swing-card-shell__size-definer"}),_vm._v(" "),_c('transition',{attrs:{"name":"show"}},[(_vm.displayProgress && _vm.localVisibleSide === 'Front')?_c('Progress',{staticStyle:{"--c-progress-offset":"var(--swing-card-shell-border-width)"},attrs:{"progress":_vm.progress,"previous-progress":_vm.previousProgress,"delay-animation":_vm.delayAnimation}}):_vm._e()],1),_vm._v(" "),(_vm.displayStickers)?_c('div',{ref:"stickersContainer",staticClass:"swing-card-shell__stickers droppable",on:{"drop":function($event){return _vm.onDrop($event)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();},"build":_vm.onBuild}},_vm._l((_vm.cardStickers),function(ref){
	var name = ref.name;
	var x = ref.x;
	var y = ref.y;
return _c('img',{key:(name + "-" + x + "-" + y),staticClass:"sticker-image sticker-image--dropped",style:({ top: ("" + y), left: ("" + x) }),attrs:{"src":("/img/stickers/" + name + ".png"),"alt":("sticker-" + name)}})}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"swing-card-shell__look"}),_vm._v(" "),_c('div',{staticClass:"swing-card-shell__content"},[_vm._t("default")],2),_vm._v(" "),_c('div',{staticClass:"swing-card-shell__content swing-card-shell__content--centered"},[_vm._t("centered")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }