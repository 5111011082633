var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notch",class:( _obj = {
		'notch--simple': _vm.simple,
		'notch--top-left': _vm.left
	}, _obj[("notch--" + _vm.size)] = _vm.size || false, _obj ),style:({
		'--notch-background-color': _vm.color || null
	})},[_c('div',{staticClass:"notch__content"},[_vm._t("default")],2),_vm._v(" "),(!_vm.simple)?_c('div',{staticClass:"notch__aside-one"},[_c('div',{staticClass:"notch__aside-inner"})]):_vm._e(),_vm._v(" "),(!_vm.simple)?_c('div',{staticClass:"notch__aside-two"},[_c('div',{staticClass:"notch__aside-inner"})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }