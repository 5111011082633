<template>
	<div
		class="c-deck-player-info"
		:class="{
			'c-deck-player-info--inverted': inverted,
			'c-deck-player-info--with-progress': !!progress,
		}"
		:style="rootStyles"
	>
		<div class="c-deck-player-info__content">
			<div
				v-if="image"
				class="c-deck-player-info__image"
				:style="{ 'background-image': `url(\'${image}\')` }"
			/>
			<div
				v-else
				class="c-deck-player-info__image c-deck-player-info__image--placeholder"
			>
				<JustLogo class="c-deck-player-info__placeholder-logo" />
			</div>
			<div class="c-deck-player-info__text-container">
				<RouterLink
					v-if="linkable"
					class="c-deck-player-info__name"
					:to="{ name: 'listDetails', params: { id: seoDeckId }}"
				>
					{{ deck.name }}
				</RouterLink>
				<span
					v-else
					class="c-deck-player-info__name"
				>
					{{ deck.name }}
				</span>
				<br>
				<RouterLink
					v-if="linkable"
					class="c-deck-player-info__author"
					:to="{ name: 'userProfile',
						params: { id: seoUserId }}"
				>
					{{ deckAuthor.displayName }}
				</RouterLink>
				<span
					v-else
					class="c-deck-player-info__author"
				>
					{{ deckAuthor.displayName }}
				</span>
			</div>
			<div v-if="playNextButtons">
				<button
					style="white-space: nowrap;"
					class="c-btn c-btn--cta c-btn--small"
					type="button"
					@click="$emit('play-next-deck', { deckId: deck._id })"
				>
					{{ $t('deckPlay.playNext') }}
				</button>
			</div>
		</div>
		<div
			v-if="progress"
			class="c-deck-player-info__progress"
		/>
	</div>
</template>

<script>
/* globals STATIC_FILES_BASE_URL */
import { deckIdToSeo } from '@/assets/js/dataServices/utils';
import JustLogo from '@/components/JustLogo.vue';

export default {
	name: 'DeckPlayerInfo',
  components: { JustLogo },
	props: {
		deck: {
			type: Object,
			required: true,
		},
		linkable: {
			type: Boolean,
			default: false,
		},
		playNextButtons: {
			type: Boolean,
			default: false,
		},
		progress: {
			type: String,
		},
		inverted: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		seoDeckId() {
			return deckIdToSeo(this.deck.name, this.deck._id);
		},
		deckAuthor() {
			return this.deck.author || {};
		},
		seoUserId() {
			return deckIdToSeo(this.deckAuthor.displayName, this.deckAuthor._id);
		},
		rootStyles() {
			const styles = {};

			if (this.progress) {
				styles['--c-deck-player-info-progress'] = this.progress;
			}

			return styles;
		},
		image() {
			const staticFileBaseUrl = STATIC_FILES_BASE_URL || '/img/';

			if (this.deck.image) {
				return `${staticFileBaseUrl}${this.deck.image.file.filename}`;
			}

			return '';
		},
	},
};
</script>

<style>
:root {
	--c-deck-player-info-padding: var(--spacing-8);
	--c-deck-player-info-padding-horizontal: var(--c-deck-player-info-padding);
	--c-deck-player-info-padding-vertical: var(--c-deck-player-info-padding);
	--c-deck-player-info-border-radius: var(--spacing-12);
	--c-deck-player-info-image-border-radius: var(--spacing-8);

	--c-deck-player-info-background: var(--grey-8);
	--c-deck-player-info-color: var(--grey-73);
	--c-deck-player-info-author-color: var(--grey-50);

	--c-deck-player-info-image-width: 40px;
	--c-deck-player-info-image-height: 40px;

	--c-deck-player-info-progress: 0%;
	--c-deck-player-info-progress-line-color: var(--grey-32);
	--c-deck-player-info-progress-background-color: var(--grey-9);

}
</style>
<style scoped>
.c-deck-player-info {
	padding: var(--c-deck-player-info-padding-vertical) var(--c-deck-player-info-padding-horizontal);
	border-radius: var(--c-deck-player-info-border-radius);
	color: var(--c-deck-player-info-color);
	background: var(--c-deck-player-info-background);
	display: flex;
	flex-direction: column;
	gap: var(--spacing-12);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
	position: relative;
}

.c-deck-player-info--with-progress.c-deck-player-info {
	padding-bottom: calc(var(--c-deck-player-info-padding-vertical) + 2px);
}

.c-deck-player-info--inverted {
	--c-deck-player-info-background: var(--meteorite);
	--c-deck-player-info-color: var(--pure-white);
	--c-deck-player-info-author-color: var(--text-color-secondary-inverted);

	--c-deck-player-info-progress-line-color: rgba(256, 256, 256, .8);
	--c-deck-player-info-progress-background-color: rgba(256, 256, 256, .2);
}

.c-deck-player-info__content {
	display: flex;
	gap: var(--spacing-8);
	align-items: center;
	flex-grow: 1;
}

.c-deck-player-info__image {
	border-radius: var(--c-deck-player-info-image-border-radius);
	background-size: cover;
	flex-shrink: 0;
	background-position: center;
	width: var(--c-deck-player-info-image-width);
	height: var(--c-deck-player-info-image-height);
}

.c-deck-player-info__image--placeholder {
	background-color: rgba(255, 255, 255, 0.15);
	display: flex;
	justify-content: center;
	align-items: center;
}

.c-deck-player-info__placeholder-logo {
  height: var(--spacing-24);
  color: var(--pure-white);
  opacity: 0.2;
  margin-left: var(--spacing-4);
  transform: rotate(-8deg);
}

.c-deck-player-info__text-container {
	overflow: hidden;
	width: 100%;
}

.c-deck-player-info__name {
	color: var(--pure-white);
	font-weight: bold;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

a.c-deck-player-info__name:active, a.c-deck-player-info__name:hover {
	text-decoration: underline;
  cursor: pointer;
}

.c-deck-player-info__author {
	color: var(--c-deck-player-info-author-color);
}

a.c-deck-player-info__author:active, a.c-deck-player-info__author:hover {
  text-decoration: underline;
}

.c-deck-player-info__progress {
	position: absolute;
	overflow: hidden;
	bottom: 3px;
	background: var(--c-deck-player-info-progress-background-color);
	height: 3px;
	border-radius: 100px;
	left: var(--c-deck-player-info-padding-horizontal);
	right: var(--c-deck-player-info-padding-horizontal);
}

.c-deck-player-info__progress::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: var(--c-deck-player-info-progress);
	background: var(--c-deck-player-info-progress-line-color);
	transition: width 200ms ease-in-out;
}

</style>
