<template>
	<span>
		<button
			type="button"
			class="c-btn c-share-deck__button"
			:class="{
				'c-btn--white': white,
				'c-btn--secondary': secondary,
				'c-btn--just-icon': !withText,
				'c-btn--icon': withText,
			}"
			@click="share"
		>
			<i class="c-btn__icon share alternate icon" />
			<span
				v-if="withText"
				class="c-btn__icon-text"
			>{{ $t('share') }}</span>
		</button>
		<SuiModal
			v-model="makeDeckPublicModalVisible"
			size="tiny"
		>
			<i
				style="color: white;"
				class="close icon"
				@click="makeDeckPublicModalVisible = false"
			/>
			<div class="c-modal-body">
				<div class="c-modal-body__hero">
					<h2
						class="ui large header inverted"
					>
						{{ $t('deckCreate.yourDeckIsPrivate') }}
					</h2>
				</div>
				<div class="c-modal-body__content">
					<span class="ui small header">{{ $t('deckCreate.makeItPublicToShare') }}</span>
					<div class="c-modal-body__footer">
						<button
							type="button"
							class="c-btn c-btn--tertiary"
							@click="makeDeckPublicModalVisible = false"
						>
							{{ $t("buttons.cancel") }}
						</button>
						<span style="width: var(--spacing-8);" />
						<TogglePrivateDeckButton
							:deck="deck"
							:cta="true"
							:white="false"
							:secondary="false"
							:inverted="false"
							:on-click="togglePrivate"
						/>
					</div>
				</div>
			</div>
		</SuiModal>
		<SuiModal
			v-model="shareModalVisible"
			size="standart"
		>
			<i
				style="color: white;"
				class="close icon"
				@click="shareModalVisible = false"
			/>
			<div class="c-modal-body">
				<FocusLock>
					<div class="c-modal-body__hero">
						<h2
							class="ui large header inverted"
						>
							{{ $t('share') }}
						</h2>
					</div>
					<div class="c-modal-body__content">
						<div class="bookmark-container">
							<div class="bookmark-content">
								<div class="bookmark-title">{{ title }}</div>
								<div class="bookmark-description">{{ description }}</div>
								<div class="bookmark-metadata">multidecker.com</div>
							</div>
							<div
								class="bookmark-thumbnail"
							><img
								class="bookmark-thumbnail-image"
								:src="image"
								alt=""
							></div>
						</div>
						<div class="link-sharing">
							<span
								class="ui small header"
								style="color: var(--shark); opacity: 0.8;"
							>{{ $t('linkForSharing') }}</span><br>
							<div style="display: flex;">
								<input
									onClick="this.select();"
									type="text"
									:value="url"
									readonly
									style="background: none; border: none; border-bottom: 1px solid var(--grey-16); font-size: 16px; flex-grow: 1; outline: none;"
								>
								<button
									type="button"
									class="c-btn c-btn--tertiary"
									style="color: var(--active)"
									@click="copyLink"
								>{{ $t('copyLink') }}</button>
							</div>
						</div>
						<div style="container-type: inline-size;">
							<div class="share-buttons-container">
								<button
									class="c-btn c-btn--icon c-btn--facebook share-buttons-container__button"
									@click="shareFacebook"
								>
									<i class="c-btn__icon facebook icon" />&nbsp;&nbsp;
									Facebook
								</button>
								<button
									class="c-btn c-btn--icon c-btn--twitter share-buttons-container__button"
									@click="shareTwitter"
								>
									<i class="c-btn__icon twitter icon" />&nbsp;&nbsp;
									Twitter
								</button>
								<button
									data-action="share/whatsapp/share"
									class="c-btn c-btn--icon c-btn--whatsapp share-buttons-container__button"
									@click="shareWhatsApp"
								>
									<i class="whatsapp icon" />
									WhatsApp
								</button>
								<button
									v-if="shareAvailable"
									type="button"
									class="c-btn"
									:class="{
										'share-buttons-container__button': !minWidth558px,
										'c-btn--just-icon': minWidth558px,
										'c-btn--icon': !minWidth558px,
									}"
									@click="shareOther"
								>
									<i class="c-btn__icon ellipsis horizontal icon" />
									<span v-if="!minWidth558px">&nbsp;&nbsp;&nbsp;{{ $t('moreOptions') }}</span>
								</button>
							</div>
						</div>
					</div>
				</FocusLock>
			</div>
		</SuiModal>
	</span>
</template>

<script>
	/* globals STATIC_FILES_BASE_URL, FB */
	import FocusLock from 'vue-focus-lock';
	import { mapGetters } from 'vuex';
	import { logAnalyticsEvent, events } from '@/assets/js/utils/analytics';
	import TogglePrivateDeckButton from '@/components/TogglePrivateDeckButton';
	import { getDeckDescription } from '@/assets/js/utils/meta';
	import { initFB } from '@/assets/js/utils/loadScript';
	import { isMobile } from '@/assets/js/utils/environment';

	export default {
		name: 'ShareDeck',
		components: {
			TogglePrivateDeckButton,
			FocusLock,
		},
		props: {
			deck: {
				type: Object,
			},
			context: {
				type: String,
			},
			white: {
				type: Boolean,
				default: true,
			},
			secondary: {
				type: Boolean,
				default: false,
			},
			withText: {
				type: Boolean,
				default: false,
			},
		},
		data: () => ({
			isMobile,
			whiteSpace: '',
			makeDeckPublicModalVisible: false,
			shareModalVisible: false,
			url: window.location.href,
		}),
		computed: {
			...mapGetters('matchMedia', ['minWidth558px']),
			shareAvailable() {
				return window.navigator.share;
			},
			title() {
				return this.deck.name;
			},
			description() {
				return getDeckDescription(this.deck);
			},
			image() {
				const staticFileBaseUrl = STATIC_FILES_BASE_URL || '/img/';

				if (this.deck.image) {
					return `${staticFileBaseUrl}${this.deck.image.file.filename}`;
				}

				return 'https://multidecker.com/img/facebook_baner.png';
			},
		},
		created() {
			initFB();
		},
		methods: {
			copyLink() {
				logAnalyticsEvent(...events.shareButtonCopyLinkClick, this.context);
				navigator.clipboard.writeText(this.url);
				const message = this.$t('linkCopiedToClipboard');
				this.$store.dispatch('setToastMessage', { message: `${message}${this.whiteSpace}` });
				// Heck: If the text is the same the toast message won't be displayed again.
				this.whiteSpace = this.whiteSpace === '' ? ' ' : '';
			},
			shareFacebook() {
				logAnalyticsEvent(...events.shareButtonFacebookClick, this.context);
				FB.ui({
					method: 'share',
					href: this.url,
				}, (response) => {
					console.log(response);
				});
				// const fbpopup = window.open('https://www.facebook.com/sharer/sharer.php?u=http://stackoverflow.com', 'pop', 'width=600, height=400, scrollbars=no');
			},
			shareTwitter() {
				logAnalyticsEvent(...events.shareButtonTwitterClick, this.context);
				// Opens a pop-up with twitter sharing dialog
				let shareURL = 'http://twitter.com/intent/tweet?';

				const checkOutThis = this.$t('checkOutThis');
				const params = {
					url: this.url,
					text: `${checkOutThis} "${this.deck.name}"`,
					hashtags: 'multidecker,flashcards',
				};
				for (const prop in params) shareURL += `&${prop}=${encodeURIComponent(params[prop])}`;

				const width = 550;
				const height = 450;
				const left = (screen.width / 2) - (width / 2);
				const top = (screen.height / 2) - (height / 2);

				window.open(shareURL, '', `left=${left},top=${top},width=${width},height=${height},personalbar=0,toolbar=0,scrollbars=0,resizable=0`);
			},
			shareWhatsApp() {
				logAnalyticsEvent(...events.shareButtonWhatsAppClick, this.context);
				window.location = `whatsapp://send?text=${this.url}`;
			},
			shareOther() {
				logAnalyticsEvent(...events.shareButtonOtherClick, this.context);
				window.navigator.share({
					title: this.title,
					text: this.description,
					url: this.url,
				});
			},
			togglePrivate() {
				this.$store.dispatch('toggleDeckPrivate', { deckId: this.deck._id });
				this.makeDeckPublicModalVisible = false;
				this.share();
			},
			share() {
				if (this.deck.private) {
					this.makeDeckPublicModalVisible = true;
					return;
				}

				logAnalyticsEvent(...events.shareButtonClick, this.context);

				this.shareModalVisible = true;
			},
		},
	};
</script>
<style scoped lang="less">
	.link-sharing {
		margin-bottom: var(--spacing-32);
	}

	.c-share-deck__button {
		width: 100%;
		height: 100%;
	}

	.bookmark-container {
		display: flex;
		margin: 0 auto var(--spacing-32) auto;
		background: var(--grey-8);
		overflow: hidden;
		border-radius: var(--spacing-12);
		max-width: 500px;
	}
	.bookmark-content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: var(--spacing-16);

		gap: var(--spacing-16);
		font-size: 16px;
	}
	.bookmark-title {
		font-size: 18px;
		font-weight: bold;
	}
	.bookmark-description {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		line-height: 1.6em;
	}
	.bookmark-metadata {
		color: var(--shark);
		opacity: 0.8;
	}
	.bookmark-thumbnail {
		position: relative;
		min-width: 33%;
		max-height: 100%;
		aspect-ratio: 1 / 1;
	}

	.bookmark-thumbnail-image {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
</style>
<style>
	.share-buttons-container {
		display: flex;
		justify-content: center;
		margin-top: var(--spacing-12);
		flex-direction: column;
		gap: 16px;
		align-items: center;
		flex-wrap: wrap;
	}

	.share-buttons-container__button {
		min-width: 170px;
	}

	@container (min-width: 500px) {
		.share-buttons-container {
			justify-content: space-evenly;
			flex-direction: row;
		}

		.share-buttons-container__button {
			min-width: auto;
		}
	}
</style>
